
import { defineComponent } from "vue";
import Dropdown3 from "@/components/dropdown/Dropdown3.vue";
import SocialSynergy from "@/components/modals/SocialSynergy.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "profile",
  components: {
    // Dropdown3,
    // SocialSynergy,
  },
  async mounted() {
    this.init();
  },
  methods: {
    init() {
      setCurrentPageBreadcrumbs("Administration", []);
    },
  },
});
